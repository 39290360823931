import { haruInfoUS } from './haru-info';
import { abraRowInfoUS } from './abra-info';
import { blockfiRowInfoUS } from './blockfi-info';
import { celsiusRowInfoUS } from './celsius-info';
import { cryptoComRowInfoUS } from './crypto-com-info';
import { finbloxRowInfoInt } from './finblox-info';
import { geminiRowInfoUS } from './gemini-info';
import { lednInfoInt } from './ledn-info';
import { hodlnautInfoUS } from './hodlnaut-info';
import { nexoRowInfoInt } from './nexo-info';
import { vauldRowInfoUS } from './vauld-info';
import { voyagerRowInfoUS } from './voyager-info';

export const slugToReferralMap = {
    [haruInfoUS.referralSlug]: haruInfoUS,
    [abraRowInfoUS.referralSlug]: abraRowInfoUS,
    [blockfiRowInfoUS.referralSlug]: blockfiRowInfoUS,
    [celsiusRowInfoUS.referralSlug]: celsiusRowInfoUS,
    [cryptoComRowInfoUS.referralSlug]: cryptoComRowInfoUS,
    [finbloxRowInfoInt.referralSlug]: finbloxRowInfoInt,
    [geminiRowInfoUS.referralSlug]: geminiRowInfoUS,
    [lednInfoInt.referralSlug]: lednInfoInt,
    [hodlnautInfoUS.referralSlug]: hodlnautInfoUS,
    [nexoRowInfoInt.referralSlug]: nexoRowInfoInt,
    [vauldRowInfoUS.referralSlug]: vauldRowInfoUS,
    [voyagerRowInfoUS.referralSlug]: voyagerRowInfoUS,
    'coinbase-referral': { slug: 'coinbase-referral', name: 'Coinbase', bonus: '$10 in BTC' },
    'ledger-referral': { slug: 'ledger-referral', name: 'Ledger', bonus: 'up to $70 in BTC' },
    'ledger-flex-promo': { slug: 'ledger-flex-promo', name: 'Ledger Flex', bonus: '$70 in BTC' },
    'ledger-stax-promo': { slug: 'ledger-stax-promo', name: 'Ledger Stax', bonus: 'None currently' },
    'ledger-nano-x-promo': { slug: 'ledger-nano-x-promo', name: 'Ledger Nano X', bonus: '$40 in BTC' },
    'ledger-nano-s-plus-promo': { slug: 'ledger-nano-s-plus-promo', name: 'Ledger Nano S Plus', bonus: '$30 in BTC' },
    'blockstream-jade-discount-code': { slug: 'blockstream-jade-discount-code', name: 'Blockstream Jade', bonus: '10% off' },
    'blockstream-jade-plus-discount-code': { slug: 'blockstream-jade-plus-discount-code', name: 'Blockstream Jade Plus', bonus: '10% off' },
    'blockstream-metal-offline-backup-discount-code': { slug: 'blockstream-metal-offline-backup-discount-code', name: 'Blockstream Metal Offline Backup', bonus: '10% off' },
};
